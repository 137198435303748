<div class="mxa-container">
  <div class="mxa-inner-container">
    <div class="content">
      <h1>Nautilus - Explore the Abyss</h1>
      <p>
        A tool set design to improve development. Made with a micro-front-end
        architecture with Svelte, Vue & React. A type of architecture where a
        web application is divided into different modules, implemented
        autonomously, allowing same level of flexibility and speed that
        microservices provide. This website uses multiple web frameworks to
        accomplish a seamless web experience:
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://single-spa.js.org/"
            referrerpolicy="origin">Single SPA</a
          >
          - Register and control the rendering of micro front end.
        </li>
        <li>
          <a
            target="_blank"
            href="https://sass-lang.com/"
            referrerpolicy="origin">SASS</a
          >
          - For the package that contains the styleguide.
        </li>
        <li>
          <a target="_blank" href="https://svelte.dev/" referrerpolicy="origin"
            >Svelte</a
          >
          - At build time framework for the navbar and the introduction.
        </li>
        <li>
          <a target="_blank" href="https://vuejs.org/" referrerpolicy="origin"
            >Vue3</a
          >
          - For the application below.
        </li>
      </ul>
      <p>
        The code is publicly available on
        <a href="https://github.com/marc-x-andre/micro-front-end">Github</a>
      </p>
    </div>
  </div>
</div>
